import { template as template_6569053380ec44a5b55e472e2edd1ce0 } from "@ember/template-compiler";
const FKLabel = template_6569053380ec44a5b55e472e2edd1ce0(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
