import { template as template_512f2086936549b4856c64115dd5f672 } from "@ember/template-compiler";
const WelcomeHeader = template_512f2086936549b4856c64115dd5f672(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
