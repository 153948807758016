import { template as template_08210729f26a47e7afbd7052ee5ab250 } from "@ember/template-compiler";
const SidebarSectionMessage = template_08210729f26a47e7afbd7052ee5ab250(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
